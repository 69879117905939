const redirectUrl = window.location.href;
export const config = {
    clientId: "ed6ffac9-4fd8-40fe-9f31-dbf68e216dbf",
    redirect: redirectUrl.replace("login", "") + "settings",
    authority: "https://login.microsoftonline.com/alvesta.onmicrosoft.com",
    scopes: ["api://ed6ffac9-4fd8-40fe-9f31-dbf68e216dbf/ScheduleUpdate"]
};

// https://arabschema.alvesta.se/settings
// https://localhost:44406/settings
export const msalConfig = {
    auth: {
        clientId: config.clientId,
        redirectUri: config.redirect,
        authority: config.authority,
        postLogoutRedirectUri: config.redirect
    }, cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
    }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: config.scopes
};
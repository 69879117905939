import { useState } from "react";
import { IconButton } from "@mui/material";
import { default as Nav } from "./Menu";
import { Menu } from "@mui/icons-material";

export default function MenuMobile(){
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mobile-menu">
      <IconButton onClick={() => setIsOpen(!isOpen)}>
        <Menu fontSize="large"/>
      </IconButton>
      {/* Hidden menu */}
      <div className={`menu-background${isOpen ? " visible-bg" : ""}`}></div>
      <Nav isOpen={isOpen} isMobile={true} close={() => setIsOpen(false)}/> 
    </div>
  );
};


import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../Config";

import "./../css/login.css";

export default function Login() {
  const { instance } = useMsal();

  const handleLogin = (instance) => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <div className="login-form-wrapper d-flex">
      <h3>Alvesta Renhållning AB</h3>
      <img
        src={require("./../assets/images/login.png")}
        alt="AlvestaRenhållningAB"
        className="login-image"
      />
      <div>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => handleLogin(instance)}
        >
          Logga in
        </Button>
      </div>
    </div>
  );
};

import { Close, Home, LogoutSharp, QueryStats, Settings } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { config } from "../Config";

export default function Menu(props) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const loc = useLocation();

  const logout = (instance) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(config.clientId),
      mainWindowRedirectUri: config.redirect,
    };

    instance.logoutPopup(logoutRequest).catch((e) => {
      console.error(e);
    });
  };

  const navigateTo = (link) => {
    let seconds = 0;
    if (props.isOpen) {
      props.close();
      seconds = 1000;
    }

    setTimeout(() => {
      navigate(link);
    }, seconds);
  };

  return (
    <div className={`menu-wrapper d-flex${props.isOpen ? " visible-menu" : ""}`}>
    <IconButton
      className={loc.pathname === "/" ? "active-link" : ""}
      onClick={() => navigateTo("/")}>
      <Home /> {props.isMobile && "Hem"}
    </IconButton>
      <IconButton
        className={loc.pathname === "/statistics" ? "active-link" : ""}
        onClick={() => navigateTo("/statistics")}>
        <QueryStats /> {props.isMobile && "Statistics"}
      </IconButton>
      <IconButton
        className={loc.pathname === "/settings" ? "active-link" : ""}
        onClick={() => navigateTo("/settings")}>
        <Settings />
        {props.isMobile && "Inställningar"}
      </IconButton>
      <IconButton onClick={() => logout(instance)}>
        <LogoutSharp /> {props.isMobile && "Logga ut"}
      </IconButton>

      {props.isMobile && (
        <IconButton onClick={props.close}>
          <Close /> Stäng
        </IconButton>
      )}
    </div>
  );
};

import { DepartureBoardOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from '../Config';
import Menu from './Menu';
import MenuMobile from "./MenuMobile";

export default function Header(props){
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (props.isAuthorized)
      RequestAccessToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthorized])

  function RequestAccessToken() {

    const request = {
      ...loginRequest, account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      sessionStorage.setItem("token", response.accessToken);
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        sessionStorage.setItem("token", response.accessToken);
      });
    });
  }

  return (
    <div className="header">
      <div className="header-logo" onClick={() => navigate("/")}>
        <span className="span-name">Alvesta Renhållnings AB</span>
        <span className="span-subname">
          <DepartureBoardOutlined fontSize="medium" /> Sophämtningsschema
        </span>
      </div>

      {props.isAuthorized && (props.width > 485 ? <Menu /> : <MenuMobile />)}
    </div>
  );
};
import { useEffect, useRef, useState } from "react";
import {
  Alert, AlertTitle, Avatar,
  Checkbox,
  List, ListItem, ListItemAvatar, ListItemText,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DepartureBoard, EventBusy, PendingActions } from "@mui/icons-material";
/* eslint-disable react-hooks/exhaustive-deps */

export default function Result({ keywords, schedule, advanced, address, updateAddress }) {
  const [noResult, setNoResult] = useState(false);
  const refs = useRef([]);

  useEffect(() => {
    if (keywords.length > 3)
      setNoResult(refs.current[0] === null || refs.current[0] === undefined);
    else
      setNoResult(false);
  }, [keywords]);

  return (
    <List className="result-wrapper">
      <div className="d-flex d-flex-mobile">
        <DepartureBoard /> &nbsp;&nbsp;<h3>Schemalagd hämtning.</h3>
      </div>

      {keywords.length < 3 && <Box sx={{ marginTop: "50px" }} className="d-flex d-flex-mobile">
        <CircularProgress color="inherit" /></Box>}

      {/* Loop of result list */}
      {keywords.length >= 3 && schedule.filter((data) => { 
        return !advanced ? (data?.address || data?.Address)?.toLowerCase()?.includes(keywords.toLowerCase())
            : (data.dateFormat || data?.DateFormat) === keywords.toLowerCase()
      }).map((s, ind) => {
        const viewAddress = (s?.address || s?.Address).replace(", undefined", "")
        const checked = (address === viewAddress);
        return <ListItem key={ind} className={`result-li${(s.dayOff || s.DayOff) ? " day-off" : ""}`} ref={(ref) => (refs.current[ind] = ref)}>
          <ListItemAvatar>
            <Avatar className="result-avatar">
              <PendingActions color="inherit" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText className="list-item" primary={viewAddress}
            secondary={<span className="date" dangerouslySetInnerHTML={{
              __html: (s?.date || s?.Date) +
                ((s?.dayOff || s?.DayOff) ? " <span class='day-off-day'>Din hämtningsdag infaller på en röd dag (helgdag)." +
                  "</br>Var vänlig kontakta kundservice att få reda på när sopbilen kommer.<span>" : "")
            }}></span>} />
          <span className="span-checkbox">Spara adress
            <Checkbox onClick={() => checked ? updateAddress() : updateAddress(viewAddress)} checked={checked} />
          </span>
        </ListItem>
      })}

      {/* If result is empty */}
      {noResult && (
        <Alert severity="error" icon={<EventBusy fontSize="large" />}>
          <AlertTitle aria-setsize={100}>Den schemalagda tiden hittades inte</AlertTitle>
              Var vänlig kontrollera din address.
        </Alert>)}
    </List>
  );
};
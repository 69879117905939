
export function TokenConfig() {
  // Jwt token to connect server
  const _token = sessionStorage.getItem("token");

  return ({
    headers: {
      'Authorization': `Bearer ${_token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*'
    }
  })
}